import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';
import FontAwesome from 'react-fontawesome';
import {Link} from 'react-router-dom';

import CategoryConstants from '../category/CategoryConstants';
import LoggedInDropdown from './LoggedInDropdown';

import './PublicHeader.css';

import UserStore from '../auth/UserStore';

const {CATEGORIES, CATEGORY_TO_INFO} = CategoryConstants;

class CustomerNavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const {override_price_level, user, customer} = this.props;

    if (!customer) {
      if (!user) {
        return null;
      }

      return (
        <nav
          className={
            classNames("navbar navbar-light", {'nav-custom-background': !override_price_level})}
          style={{backgroundColor: override_price_level ? 'gold' : null}}
        >
          <div className="container">
            <span className="navbar-text text-light mx-auto py-0">
              {user.email}
            </span>
            <LoggedInDropdown user={user} customer={customer}/>
          </div>
        </nav>
      )
    }

    let customer_name = customer.name;
    if (user.user_type === 'Employee') {
      customer_name = (
        <Link to={(user.rep_code ? '' : '/internal') + `/customers/${customer.id}`} className="text-light">{customer.name}</Link>
      )
    }

    // const price_display = override_price_level
    //   ? util.PRICE_LEVEL_TO_PRICE_GROUP[override_price_level] : customer.price_group;

    return (
      <nav
        className={
          classNames("navbar navbar-light", {'nav-custom-background': !override_price_level})}
        style={{backgroundColor: override_price_level ? 'gold' : null}}
      >
        <div className="container">
          <span className="navbar-text text-light mx-auto py-0">
            {user.email} - {customer_name}
          </span>
          <LoggedInDropdown user={user} customer={customer}/>
        </div>
      </nav>
    )
  }
}

CustomerNavBar.propTypes = {
  customer: PropTypes.object,
  user: PropTypes.object,
  override_price_level: PropTypes.string,
}

class PublicHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: UserStore.get_user(),
      customer: UserStore.get_customer(),
      site_setup: UserStore.get_site_setup(),
    }
  }

  componentDidMount() {
    UserStore.on('change', this.setUserFromStore);
  }

  componentWillUnmount() {
    UserStore.removeListener('change', this.setUserFromStore);
  }

  setUserFromStore = () => {
    this.setState({
      user: UserStore.get_user(),
      customer: UserStore.get_customer(),
      site_setup: UserStore.get_site_setup(),
    })
  }

  render() {
    const {user, customer, site_setup} = this.state;
    let current_price_level = null;

    if (user) {
      current_price_level = user.current_price_level;
    }

    const user_is_active = user && user.is_active;
    const user_is_employee = user && user.user_type === 'Employee';

    return (
      <div>
        {site_setup && site_setup.top_ribbon && (
          <div dangerouslySetInnerHTML={{__html: site_setup.top_ribbon}} />
        )}

        <CustomerNavBar customer={customer} user={user} override_price_level={current_price_level}/>

        <nav className="publicHeader navbar navbar-expand-md navbar-light bg-light">
          <div className="container">
            <Link className='navbar-brand p-0' to="/">
              <img alt='Logo' className="logo-image" src="/static/logo.png"/>
            </Link>

            {site_setup && site_setup.appointment_title && (
              <div dangerouslySetInnerHTML={{__html: site_setup.appointment_title}} />
            )}

            <ul className="navbar-nav d-xs-block d-sm-none">
              <li className="nav-item">
                <Link className="nav-link" to="/search">
                  <FontAwesome name="search"/>
                </Link>
              </li>
            </ul>

            <ul className="navbar-nav d-xs-block d-sm-none">
              <li className="nav-item">
                <Link className="nav-link" to="/scanner">
                  <FontAwesome name="camera"/>
                </Link>
              </li>
            </ul>

            {user_is_active && (
              <ul className="navbar-nav d-xs-block d-sm-none">
                <li className="nav-item">
                  <Link className="nav-link" to="/cart">
                    <FontAwesome name="shopping-cart"/>
                  </Link>
                </li>
              </ul>
            )}

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
            >
              <span className="navbar-toggler-icon"></span>
            </button>


            <div className="collapse navbar-collapse flex-row-reverse" id="navbarSupportedContent">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link" to='/whats-new'>New Products</Link>
                </li>

                <div className="nav-item dropdown">
                  <button type="button"
                    className="btn btn-link shadow-none nav-link dropdown-toggle"
                    id="publicHeader-categories-dropdown'"
                    data-toggle="dropdown"
                  >
                    Product Categories
                  </button>

                  <div className="dropdown-menu">
                    {CATEGORIES.map((category) => (
                      <Link className="dropdown-item" to={`/category/${category}`} key={category}>
                        {CATEGORY_TO_INFO[category].display}
                      </Link>
                    ))}
                    <Link className="dropdown-item" to='/search'>All Products</Link>
                    <div className="dropdown-divider"/>
                    <Link className="dropdown-item" to='/contract-products'>Contract Grade</Link>

                    {user && user.is_obs_customer && (
                      <Link className="dropdown-item" to='/free-products'>Truckload Products</Link>
                    )}

                    {user_is_employee && (
                      <div style={{backgroundColor: 'gold'}}>
                        <div className="dropdown-divider"/>

                        <Link className="dropdown-item" to='/ecommerce-products'>eCommerce Products</Link>
                        <Link className="dropdown-item" to='/hidden-products'>Hidden Products</Link>
                      </div>
                    )}
                  </div>
                </div>

                <li className="nav-item">
                  <Link className="nav-link" to='/pages/eCatalogs'>eCatalogs</Link>
                </li>

                <div className="nav-item dropdown">
                  <button type="button"
                    className="btn btn-link shadow-none nav-link dropdown-toggle"
                    id="publicHeader-about-us-dropdown'"
                    data-toggle="dropdown"
                  >
                    About
                  </button>

                  <div className="dropdown-menu" id='publicHeader-about-us-dropdown'>
                    <Link className="dropdown-item" to='/about/history'>Overview</Link>
                    <Link className="dropdown-item" to='/newsletter'>Newsletter</Link>
                    <Link className="dropdown-item" to='/about/showrooms'>Showrooms</Link>
                    <Link className="dropdown-item" to='/pages/terms'>Terms & Conditions</Link>
                    <Link className="dropdown-item" to='/about/map'>MAP Policy</Link>
                    <Link className="dropdown-item" to='/about/privacy'>Privacy Policy</Link>

                    <div className="dropdown-divider"/>

                    <Link className="dropdown-item" to='/requests/catalog'>Request Catalog</Link>
                    <Link className="dropdown-item" to='/requests/swatch'>Request Swatch</Link>
                  </div>
                </div>

                <li className="nav-item">
                  <Link className="nav-link" to='/pages/assets'>Assets</Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link" to="/search">
                    Search <FontAwesome name="search"/>
                  </Link>
                </li>

                {user_is_active && site_setup.sale_groups == null && (
                  <li className="nav-item">
                    <Link className="nav-link" to='/sale/All'>Sale</Link>
                  </li>
                )}

                {user_is_active && site_setup.sale_groups && (
                  <div className="nav-item dropdown">
                    <button type="button"
                      className="btn btn-link shadow-none nav-link dropdown-toggle"
                      id="publicHeader-sales-dropdown'"
                      data-toggle="dropdown"
                    >
                      Sale
                    </button>

                    <div className="dropdown-menu">
                      <Link className="dropdown-item" to={`/sale/All`} key="All">All</Link>
                      {site_setup.sale_groups.split(";").map((group) => (
                        <Link className="dropdown-item" to={`/sale/${group.replace("%","_")}`} key={group}>{group}</Link>
                      ))}
                    </div>
                  </div>
                )}

                {user_is_active && (
                  <li className="nav-item">
                    <Link className="nav-link" to="/cart">
                      Cart <FontAwesome name="shopping-cart"/>
                    </Link>
                  </li>
                )}

                {!user && (
                  <LoggedInDropdown user={user} customer={customer}/>
                )}
              </ul>
            </div>
          </div>
        </nav>
        {site_setup && site_setup.show_banner && (
          <div className="container contents-container publicHeader" dangerouslySetInnerHTML={{__html: site_setup.banner_title}} />
        )}
      </div>
    );
  }
}

export default PublicHeader;
